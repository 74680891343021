import i18n from '@/vue-app/plugins/i18n';
import TYPES from '@/types';
import Vue from 'vue';

// Application
import {
  MonthlyCollectionDaysService,
} from '@/modules/my-investment/allianz/recurring-contributions/application/services';

// Domain
import {
  AllianzAccountReportEntity,
} from '@/modules/my-investment/allianz/allianz-account-report/domain/entities/allianz-account-report-entity';
import {
  MonthlyCollectionDayOptionEntity,
} from '@/modules/my-investment/allianz/allianz-account/domain/entities/monthly-collection-day-option-entity';
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import {
  StateManager as ContributionsStateManager,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/state/state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';

export default class SchedulePaymentDateWealthViewModel {
  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.ALLIANZ_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
  private readonly contributions_state_manager!:
    ContributionsStateManager;

  @Inject(TYPES.MONTHLY_COLLECTION_DAYS_SERVICE)
  readonly monthly_collection_days_service!: MonthlyCollectionDaysService;

  @Inject(TYPES.DATETIME_VALUE)
  datetimeValue!: DatetimeValue;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-schedule-payment-date-wealth';

  AVAILABLE_DAYS = [...Array(28).keys()].map((i) => ({ item: `${i18n.t(`${this.i18n_namespace}.select_option`, { option: i + 1 })}`, value: i + 1 }));

  funds: Array<AllianzAccountReportEntity> = [];

  change_date = true;

  payment_day = 0;

  current_payment_day = 0;

  has_recurring_contributions = true;

  recurrent_assigned_amount = 0;

  investor_goal_state = this.create_investor_goal_state_manager.state;

  contributions_state = this.contributions_state_manager.state;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (value: string, options?: Record<string, string | number>) => {
    if (options) {
      return i18n.tc(`${this.i18n_namespace}.${value}`, 0, options) as string;
    }
    return i18n.t(`${this.i18n_namespace}.${value}`) as string;
  }

  initialize = async () => {
    this.investor_goal_state.is_loading = true;
    await this.loadContributionInformation();
    this.investor_goal_state.is_loading = false;
  }

  changeDate = () => {
    if (this.change_date) {
      this.payment_day = 1;
    } else {
      this.payment_day = this.current_payment_day;
    }
  }

  updatePaymentDate = async () => {
    try {
      this.investor_goal_state.is_loading = true;
      this.investor_goal_state.first_goal_contribution.collection_day = this.payment_day;
      this.view.$emit('createAndLinkGoal');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_payment_date'));
    } finally {
      this.investor_goal_state.is_loading = false;
    }
  }

  loadContributionInformation = async () => {
    if (!this.contributions_state.item.home_desired) {
      this.has_recurring_contributions = false;
      this.payment_day = 1;
    } else {
      this.payment_day = this.contributions_state.item.contributions[0].collection_day;
      this.current_payment_day = this.contributions_state.item.contributions[0].collection_day;
    }
  }

  goToPrevStep = () => {
    if (this.contributions_state.item.contributions.length >= 2
      || this.investor_goal_state.total_goal_contribution > 50000) {
      this.view.$emit('prevStep');
    } else {
      this.view.$emit('prevStep');
      this.view.$emit('prevStep');
    }
  }
}
